import React, {useContext} from 'react'
import * as styles from './HeaderCard.module.css'
import DarkmodeContext from '../../../state/darkmodel-context'


export default function HeaderCard({title, subtext, className=""}) {
    const {darkMode: isDark} = useContext(DarkmodeContext)

    return (
        <>
            <div className={`${styles.card} ${className} ${isDark?styles.dark:''}`}>
            <img src={`/images/hi.svg`} alt="hi emoji"/>
            <div>
                <div className={styles.shallow_font}>{subtext}</div>
                <div>
                    {title}
                </div>
            </div>
            </div>
            {/* <div className={`${styles.card} ${styles.card_back} ${className}`}></div> */}
        </>
    )
}
