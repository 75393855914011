import React, {useContext} from 'react'
import * as styles from './SecondaryCard.module.css'
import DarkmodeContext from '../../../state/darkmodel-context'


export default function SecondaryCard({title, subtitle, className=""}) {
    const {darkMode: isDark} = useContext(DarkmodeContext)

    return (
        <div className={`${styles.second_card} ${className} ${isDark? styles.dark: ''}`}>
            <div>
                <div>{title}</div>
                <div>{subtitle}</div>
            </div>
        </div>
    )
}
