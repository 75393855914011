import React, {useContext} from 'react'
import { StaticImage } from 'gatsby-plugin-image';
import HeaderCard from '../cards/headerCard/HeaderCard';
import SecondaryCard from '../cards/secondaryCard/SecondaryCard';
import DarkmodeContext from '../../state/darkmodel-context';

export default function Sidebar({styles}) {
    const {darkMode: isDark} = useContext(DarkmodeContext);

    return (
        <div>
            {
                isDark?
                <StaticImage 
                    src="../../images/center profile dark.png"
                    placeholder="none"
                    className={styles.profile}
                    alt="photo of angshuman barpujari"
                />:
                <StaticImage 
                    src="../../images/center profile.png"
                    placeholder="none"
                    className={styles.profile}
                    alt="photo of angshuman barpujari"
                />
            }
            <HeaderCard
                className={styles.card}
                title={`Angshuman\nBarpujari`}
                subtext={`Hello, I am`}
            />

            <SecondaryCard
                className={styles.secondary_card}
                title={`Flutter Developer`}
                subtitle={`UI/UX Designer`}
            />
        </div>
    )
}
